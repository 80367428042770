import React from 'react';
import './TerminalTyper.css'

export default class TerminalTyper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            intervalTime: 250,
            showCursor: 'transparent' ,
            cursorPos: -4,
            command: 0,
            display: '',
            commands: props.commands,
            repeat: props.repeat,
            output: props.output
        };
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async displayHiddenTerminals() {
        let hidden = document.getElementsByClassName("hidden-terminal");
        for (let i = 0; i <= hidden.length; i++) {
            hidden[0].classList.remove("hidden-terminal");
            await this.sleep(400);
        }
    }

    


    typeTick() {
        if (this.state.cursorPos < 0 && this.state.showCursor === 'white'){
            this.setState(state => ({
                showCursor: 'transparent'
            }));

        } 
        else {
            this.setState(state => ({
                showCursor: 'white'
            }));
        }
        if (this.state.cursorPos >= 0 && this.state.cursorPos < this.state.commands[this.state.command].length ) {
            clearInterval(this.interval);
            this.setState (state =>({
                intervalTime: 50,
                display: this.state.display + this.state.commands[this.state.command][this.state.cursorPos]
            }));
            this.interval = setInterval(() => this.typeTick(), this.state.intervalTime);
            
        } else if (this.state.cursorPos >=this.state.commands[this.state.command].length ) {
            clearInterval(this.interval);
            if (this.state.repeat) {
                this.setState (state =>({
                    cursorPos: -4,
                    command: this.state.command + 1 >=  this.state.commands.length ? 0 : this.state.command + 1,
                    intervalTime: 250,
                    display: ""
                }));
                this.interval = setInterval(() => this.typeTick(), this.state.intervalTime);
            } else {
                this.setState (state => ({
                    showCursor:'transparent'
                }));
                this.displayHiddenTerminals();
            }
            
            
        }
        this.setState (state => ({
            cursorPos: this.state.cursorPos+1
        }));

    }

    componentDidMount() {
        this.interval = setInterval(() => this.typeTick(), this.state.intervalTime);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }
    render() {
        return (
            <>
                <div className={this.props.classNames}><span className="username">Trevor-Simpkin@dev</span>:<span className="filesystem">~/</span>${this.state.display}<span style={{color: this.state.showCursor}}>▮</span></div>
            </>
          );
    }
}