import React, { useState } from 'react';
import { FaAlignRight } from 'react-icons/fa';
import './NavBar.css'

export default function NavBar(props) {
    const [toggle, setToggle] = useState(false);
    const logo = "/gopher_color.png"
    const pages = [
        {
            link: "/about",
            text:"About"
        }

      ];
    

    return (
        <>
            <div className="navBar">
                  <button onClick={()=>setToggle(!toggle)}>
                      <FaAlignRight />
                  </button>
                  <a className="logo" href="/"><img className="logo" src={logo} alt="gopher"></img></a>
                  <ul className={toggle ? "nav-links show-nav" : "nav-links"}>
                    {
                        pages.map((objLink, i) => {
                            return ( <li key={i} ><a href={objLink.link}>{objLink.text}</a></li> )
                        })
                    }
                  </ul>
            </div>
        </>
      );

}