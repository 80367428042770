import React from 'react';
import NavBar from './Containers/NavBar/NavBar'
import './App.css';
import Routes from './Routes';



function App() {
  return (
    <div className="App black-background">
      <NavBar />
      <Routes />
    </div>
  );
}

export default App;
