import React from "react";
import { Route, Switch } from "react-router-dom";
import Title from "./Containers/Title/Title";
import About from "./Containers/About/About";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Title />
      </Route>
      <Route exact path="/about">
        <About />
      </Route>
    </Switch>
  );
}
