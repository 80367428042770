import React from 'react';
import '../TerminalTyper/TerminalTyper'
import './About.css'
import TerminalTyper from '../TerminalTyper/TerminalTyper';

export default class About extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            classNames: "hidden-terminal about-text"
        }
    }

    render() {
        return (
            <>
            <div className="container">
            <TerminalTyper classNames="terminal" commands={["man trevor"]} repeat={false}/>
                <div className={this.state.classNames}>
                    <p>NAME</p>
                    <p className="indent"> Trevor Simpkin</p>
                    <br />
                    <p>SYNOPSIS</p>
                    <p className="indent">[Cloud Developer] [Web Developer]</p>
                    <br />
                    <p>DESCRIPTION</p>
                    <p className="indent">Currently a Cloud Developer II at <a href="https://investor.vanguard.com/home"> Vanguard</a>, 
                    where I provide infrastructure needs to enable big data analytics services hosted on Amazon Web Services (AWS). I also provide web
                    development needs to Paul Carpenter Associates, Inc., an environmental firm that provides monitoring and consulting services across the United States.</p>
                    <p className="indent">I have a Bachelors in Physics, Masters in Information Systems, and I am a certified AWS Solutions Architect Associate. 
                    I spend my days providing cloud solutions for Vanguard's analytics platforms. During my own time, I love working on personal projects 
                    to build my own IoT systems or mobile gaming rigs.</p>
                    <p className="indent">If you are interested in working on a project together, please send me an <a href="mailto:trevorsimpkin@gmail.com">email</a> </p>
                </div>
            <TerminalTyper classNames="hidden-terminal terminal" commands={[""]} repeat={true}/>
            </div>
            
            </>
          );
    }
}