import React from 'react';
import './Title.css';
import '../TerminalTyper/TerminalTyper'
import TerminalTyper from '../TerminalTyper/TerminalTyper';

export default class Title extends React.Component {

    render() {
        return (
            <TerminalTyper classNames="center terminal" commands={["git add --all", "git commit -m \"Code a bunch of stuff;\"", "git push"]} repeat={true} />
          );
    }
}